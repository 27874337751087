import vap from "./VAP.jpeg";
import vap2 from "./VAP/plaza.webp";
import vap3 from "./VAP/promenade.webp";
import vap4 from "../About/VAP-about.jpeg";
import vap5 from "../Hero/VAP-hero.jpeg";
import latinos from "./Latinos.png";
import latinos5 from "./Latinos/Latinos 2.jpeg";
import latinos6 from "./Latinos/Latinos 3.jpeg";
import latinos7 from "./Latinos/Latinos 4.jpeg";
import latinos8 from "./Latinos/Latinos 5.jpeg";
import latinos9 from "./Latinos/Latinos 6.jpeg";
import latinos10 from "./Latinos/Latinos 7.jpeg";
import latinos11 from "./Latinos/Latinos 8.jpeg";
import latinos12 from "./Latinos/Latinos 9.jpeg";
import latinos13 from "./Latinos/Latinos 10.jpeg";
import latinos14 from "./Latinos/Latinos 11.jpeg";
import loop from "./The Loop.jpeg";
import loop2 from "../About/loop-about.webp";
import loop3 from "../Hero/loop-hero.webp";
import loop4 from "./The loop/loop 2.jpeg";
import loop5 from "./The loop/loop 3.jpeg";
import loop6 from "./The loop/loop 4.jpeg";
import loop7 from "./The loop/loop 5.jpeg";
import loop8 from "./The loop/loop 6.jpeg";
import va from "./VA.png";
import va2 from "../Hero/VA-hero.jpeg";
import wvp from "./WVP.webp";
import wvp2 from "../About/WVP-about.jpeg";
import wvp3 from "../Hero/WVP-hero.jpeg";
import wvp4 from "./West Village Park/WVP 2.jpeg";
import wvp5 from "./West Village Park/WVP 3.jpeg";
import wvp6 from "./West Village Park/WVP 4.jpeg";
import wvp7 from "./West Village Park/WVP 5.jpeg";
import wvp8 from "./West Village Park/WVP 6.jpeg";
import wvp9 from "./West Village Park/WVP 7.jpeg";
import wvp10 from "./West Village Park/WVP 8.jpeg";
import xlane from "./Xlane.png";
import xlane2 from "../Hero/Xlane-hero.jpeg";
import xlane3 from "../About/xlane-about.jpeg";
import xlane4 from "./Xlane/Xlane (1).jpeg";
import xlane5 from "./Xlane/Xlane (2).jpeg";
import xlane6 from "./Xlane/Xlane (3).jpeg";
import xlane7 from "./Xlane/Xlane (4).jpeg";
import xlane8 from "./Xlane/Xlane (5).jpeg";
import xlane9 from "./Xlane/Xlane (6).jpeg";
import xlane10 from "./Xlane/Xlane (7).jpeg";
import nu from "./NU.webp";
import nu2 from "../Hero/NU-hero.jpeg";
import nu3 from "../About/NU-about.jpeg";
import dr from "./DR.webp";
import dr2 from "../Hero/Delrey-hero.webp";
import dr3 from "../About/Delrey-about.webp";
import dr4 from "./Delrey/Delrey (1).webp";
import dr5 from "./Delrey/Delrey (2).webp";
import dr6 from "./Delrey/Delrey (3).webp";
import dr7 from "./Delrey/Delrey (4).webp";
import dr8 from "./Delrey/Delrey (5).webp";
import dr9 from "./Delrey/Delrey (6).webp";
import dr10 from "./Delrey/Delrey (7).webp";
import dr11 from "./Delrey/Delrey (8).webp";
import dr12 from "./Delrey/Delrey (9).webp";
import dr13 from "./Delrey/Delrey (10).webp";
import dr14 from "./Delrey/Delrey (11).webp";
import dr15 from "./Delrey/Delrey (12).webp";
import dr16 from "./Delrey/Delrey (13).webp";
import dr17 from "./Delrey/Delrey (14).webp";
import ttx from "./TTX.jpeg";
import ttx3 from "../About/TTX-about.jpeg";
import cc from "./CC.webp";
import cc2 from "../Hero/CC-hero.webp";
import cc3 from "../About/CC-about.webp";
import npt from "./NPT.webp";
import npt2 from "../Hero/NPT-hero.jpeg";
import npt3 from "../About/NPT-about.jpeg";
import nbs from "./ss1.png";
import nbs2 from "./ss2.png";
import nbs3 from "./ss3.png";
import nbs4 from "./ss4.png";
const vapImages = [vap, vap2, vap3, vap4, vap5];
const latinosImages = [
  latinos,
  latinos5,
  latinos6,
  latinos7,
  latinos8,
  latinos9,
  latinos10,
  latinos11,
  latinos12,
  latinos13,
  latinos14,
];
const loopImages = [loop, loop2, loop3, loop4, loop5, loop6, loop7, loop8];
const vaImages = [va, va2];
const wvpImages = [wvp, wvp2, wvp3, wvp4, wvp5, wvp6, wvp7, wvp8, wvp9, wvp10];
const xlaneImages = [
  xlane,
  xlane2,
  xlane3,
  xlane4,
  xlane5,
  xlane6,
  xlane7,
  xlane8,
  xlane9,
  xlane10,
];
const nuImages = [nu, nu2, nu3];
const drImages = [
  dr,
  dr2,
  dr3,
  dr4,
  dr5,
  dr6,
  dr7,
  dr8,
  dr9,
  dr10,
  dr11,
  dr12,
  dr13,
  dr14,
  dr15,
  dr16,
  dr17,
];
const ttxImages = [ttx, ttx3];
const ccImages = [cc, cc2, cc3];
const nptImages = [npt, npt2, npt3];
const nbsImages = [nbs, nbs2, nbs3, nbs4];
export {
  vapImages,
  latinosImages,
  loopImages,
  vaImages,
  wvpImages,
  xlaneImages,
  nuImages,
  drImages,
  ttxImages,
  ccImages,
  nptImages,
  nbsImages,
};
