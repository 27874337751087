import typea from "./Type a.png";
import typea2 from "./Type a 2.png";
import typeb from "./Type b.png";
import typeb2 from "./type b 2.png";
import typec from "./type c.png";
import typec2 from "./type c 2.png";
import typed from "./type d.png";
import typed2 from "./type d 2.png";

const typeaImages = [typea, typea2];
const typebImages = [typeb, typeb2];
const typecImages = [typec, typec2];
const typedImages = [typed, typed2];

export { typeaImages, typebImages, typecImages, typedImages };
